// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_tR";
export var lbContainerInner = "z_tS";
export var movingForwards = "z_tT";
export var movingForwardsOther = "z_tV";
export var movingBackwards = "z_tW";
export var movingBackwardsOther = "z_tX";
export var lbImage = "z_tY";
export var lbOtherImage = "z_tZ";
export var prevButton = "z_t0";
export var nextButton = "z_t1";
export var closing = "z_t2";
export var appear = "z_t3";